<template>
  <div>
    <Loading></Loading>
    <!-- banner -->
    <div class="banner" :style="cover">
      <div class="bgShade">
        <h1 class="banner-title">相册</h1>
      </div>
    </div>
    <!-- 相册内容 -->
    <v-card class="blog-container">
      <v-row>
        <v-col :md="6" v-for="item of photoAlbumList" :key="item.id">
          <div class="album-item">
            <v-img class="album-cover" :src="item.cover" />
            <router-link :to="'/albums/' + item.id" class="album-wrapper">
              <div class="album-name">{{ item.name }}</div>
              <div class="album-desc">{{ item.info }}</div>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import { getAlbum } from "../../api";
  import Loading from "@/components/loading/loading";

  export default {
    components: {
      Loading
    },
    created() {
      this.listPhotoAlbums();
    },
    metaInfo: {
      meta: [{
        name: "keyWords",
        content: "儒林小栈,开源博客,www.codescholar.cn"  //变量或字符串
      }, {
        name: "description",
        content: "一个专注于技术分享的博客平台,大家以共同学习,乐于分享,拥抱开源的价值观进行学习交流"
      }]
    },
    data: function() {
      return {
        photoAlbumList: []
      };
    },
    methods: {
      listPhotoAlbums() {
        getAlbum().then(res => {
          this.photoAlbumList = res.data;
        });
      }
    },
    computed: {
      cover() {
        var cover = "";
        this.$store.state.blogInfo.pageList.forEach(item => {
          if (item.pageLabel === "album") {
            cover = item.pageCover;
          }
        });
        return "background: url(" + cover + ") center center / cover no-repeat";
      }
    }
  };
</script>

<style scoped>
    .album-item {
        overflow: hidden;
        position: relative;
        cursor: pointer;
        background: #000;
        border-radius: 0.5rem !important;
    }

    .album-cover {
        position: relative;
        max-width: none;
        width: calc(100% + 1.25rem);
        height: 250px;
        opacity: 0.8;
        transition: opacity 0.35s, transform 0.35s;
        transform: translate3d(-10px, 0, 0);
        object-fit: cover;
    }

    .album-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.8rem 2rem;
        color: #fff !important;
    }

    .album-item:hover .album-cover {
        transform: translate3d(0, 0, 0);
        opacity: 0.4;
    }

    .album-item:hover .album-name:after {
        transform: translate3d(0, 0, 0);
    }

    .album-item:hover .album-desc {
        opacity: 1;
        filter: none;
        transform: translate3d(0, 0, 0);
    }

    .album-name {
        font-weight: bold;
        font-size: 1.25rem;
        overflow: hidden;
        padding: 0.7rem 0;
        position: relative;
    }

    .album-name:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #fff;
        content: "";
        transition: transform 0.35s;
        transform: translate3d(-101%, 0, 0);
    }

    .album-desc {
        margin: 0;
        padding: 0.4rem 0 0;
        line-height: 1.5;
        opacity: 0;
        transition: opacity 0.35s, transform 0.35s;
        transform: translate3d(100%, 0, 0);
    }
</style>
